<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Filtrar por </strong>
        <button
          type="button"
          class="btn-sm btn-info float-right"
          @click="mtd_send_register"
        >
          <i class="fas fa-plus"></i>&nbsp;Nuevo Acopio
        </button>
      </CCardHeader>
      <CCardBody>
        <!-- Para buscar por proyecto-->
        <CRow>
          <CCol sm="2">
            <CInput
              label="Documento del provedor"
              placeholder="12345678"
              v-model="filter.document"
            />
          </CCol>
          <CCol sm="3">
            <CInput
              type="date"
              label="Fecha Inicio"
              v-model="filter.date_initial"
            >
            </CInput>
          </CCol>
          <CCol sm="3">
            <CInput
              type="date"
              label="Fecha Fin"
              v-model="filter.date_end"
              :min="filter.date_initial"
            >
            </CInput>
          </CCol>
          <CCol sm="2">
            <label>&nbsp;</label>
            <br />
            <CButton class="w-100" type="submit" color="primary" @click="mtd_search_filter()">
              <i class="fa fa-search"></i> Buscar Acopios
            </CButton>
          </CCol>
          <!-- <CCol sm="2"> &nbsp; </CCol> -->
          <!-- <CCol sm="2"><br>
                        <CButton type="submit" color="success" @click="mtd_export_pdf()">
                            <i class="fa fa-file-pdf"></i> Reporte PDF
                        </CButton>
                    </CCol> -->
          <CCol sm="2">
            <label>&nbsp;</label>
            <br />
            <CButton
              class="w-100"
              type="submit"
              color="success"
              @click="mtd_export_excel()"
            >
              <i class="fa fa-file-excel"></i> Reporte EXCEL
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de Acopios"
          icon="fas fa-dolly"
          btn_name="acopio"
          :size="'lg'"
          :button_new="false"
          :actions="true"
          :withActions="'10%'"
          :buttonEdit="true"
          :buttonDelete="true"
          :myButtons="myButtons"
          @mtd_view_pdf="mtd_view_pdf"
          @action_delete="mtd_delete"
          @action_edit="mtd_edit"
        />
      </CCol>
    </CRow>
    <!-- modal delete -->
    <cModalDelete
      title="Borrar Acopio"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>
  </div>
</template>

<script>
const fields = [
  { key: "id_acopio", label: "Id", _style: "width:3%" },
  { key: "date", label: "Fecha", _style: "width:5%" },
  { key: "document", label: "Ruc/dni", _style: "width:20%;" },
  { key: "supplier", label: "Proveedor", _style: "width:20%;" },
  { key: "alias", label: "Alias", _style: "width:20%;" },
  { key: "serie", label: "Serie", _style: "width:20%;" },
  { key: "ticket", label: "TK", _style: "width:5%;" },
  { key: "muestra", label: "Muestra", _style: "width:20%;" },
  { key: "cascara", label: "Cas%", _style: "width:20%;" },
  { key: "initial", label: "P. inicial", _style: "width:12%;" },
  { key: "rendimiento", label: "RF%", _style: "width:12%;" },
  { key: "humedad", label: "H%", _style: "width:5%;" },
  { key: "descuento", label: "Dst H.", _style: "width:12%;" },
  { key: "peso_bruto", label: "P. Bruto", _style: "width:12%;" },
  { key: "num_sacos", label: "N° S", _style: "width:12%;" },
  { key: "tara_saco", label: "Tara S", _style: "width:12%;" },
  { key: "taza", label: "Taza", _style: "width:12%;" },
  { key: "peso_neto", label: "P. Neto", _style: "width:12%;" },
  { key: "neto_num_qq", label: "N° QQ", _style: "width:12%;" },
  { key: "precio_qq", label: "Precio QQ", _style: "width:12%;" },
  { key: "precio_kg", label: "Precio Kg", _style: "width:12%;" },
  { key: "liq", label: "Total Liq.", _style: "width:12%;" },
  { key: "detail", label: "Detalle", _style: "width:37%;" },
  { key: "certi", label: "Certificaciones", _style: "width:37%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

import exportFromJson from "export-from-json";

export default {
  components: { CTableWrapper, cModalDelete },
  data() {
    return {
      prefix: "acopio",
      fields,
      data: [],
      filter: {
        document: null,
        date_initial: "",
        date_end: "",
      },
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Ticket PDF",
          action: "mtd_view_pdf",
          icon: "fas fa-file-alt",
        },
      ],
      load: {
        excel: false,
      },
      excel:[],
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  created() {
    let actual = new Date();
    let fecha;
    fecha =
      actual.getFullYear() +
      "-" +
      ("0" + (actual.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + actual.getDate()).slice(-2);
    // this.filter.date_initial = fecha;
    this.filter.date_end = fecha;

    let temp = new Date();
    temp.setDate(temp.getDate() - 5);
    this.filter.date_initial =
      temp.getFullYear() +
      "-" +
      ("0" + (temp.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + temp.getDate()).slice(-2);
    this.mtd_search_filter();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_search_filter: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/searchFilter",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          if (response.state == 0) {
            this.data = response.data.map((item, order) => {
              return { ...item, order };
            });

            if (this.data.length >= 1) {
              this.load.excel = true;
            } else {
              this.load.excel = false;
            }
            /*** excel */
            this.excel=[];
            this.data.forEach(element => {
              this.excel.push({
                Fecha: element.date,
                'Ruc/dni':element.document,
                'Proveedor':element.supplier,
                'Alias':element.alias,
                'Serie':element.serie,
                'TK':element.ticket,
                'Muestra':element.muestra,
                'Cas%':element.cascara,
                'P. inicial':element.initial,
                'RF%':element.rendimiento,
                'H%':element.humedad,
                'Dst H.':element.descuento,
                'P. Bruto':element.peso_bruto,
                'N° S':element.num_sacos,
                'Tara S':element.tara_saco,
                'Taza':element.taza,
                'P. Neto':element.peso_neto,
                'N° QQ':element.neto_num_qq,
                'Precio QQ':element.precio_qq,
                'Precio Kg':element.precio_kg,
                'Total Liq.':element.liq,
                'Detalle':element.detail,
                'Certificaciones':element.certi,
              });
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: "EL DOCUMENTO NO EXISTE",
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_export_excel: function () {
      const datos = this.excel;
      const fileName = "acopios";
      const typeExport = exportFromJson.types.xls;
      exportFromJson({
        data: datos,
        fileName: fileName,
        exportType: typeExport,
      });
      // this.post({
      //   url:
      //     this.$store.getters.get__url +
      //     "/" +
      //     this.prefix +
      //     "/" +
      //     "GenerateEXCEL",
      //   token: this.$store.getters.get__token,
      //   params: this.filter,
      // })
      //   .then((response) => {
      //     this.download(response);
      //   })
      //   .catch((errors) => {
      //     console.log(errors);
      //   });
    },
    // descarga el archivo
    download: function (data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "acopio.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    mtd_export_pdf: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/GeneratePDF",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          window.open("/assets/sales/" + response + ".pdf", "_blank");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    mtd_view_pdf: function (item) {
      window.open("/assets/acopio/" + item.uuid + ".pdf", "_blank");
    },
    mtd_send_register: function () {
      this.$router.push({ path: "acopio/register" });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_edit: function (id) {
      this.$router.push({ path: "acopio/edit/" + id });
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id_acopio,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            let temp = [];
            // this.data.forEach((element) => {
            //   if (element.id_detalle_acopio != response.id_detalle_acopio) {
            //     temp.push(element);
            //   }
            // });
            this.data = temp;
            this.modal_delete = {
              boo: false,
              item: [],
            };
            bus.$emit("alert", {
              color: "success",
              message: "ELIMINADO CORRECTAMENTE",
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>